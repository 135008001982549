// controls.js

export function createCustomButton(text, callback) {
    const button = document.createElement('button');
    button.textContent = text;
    button.classList.add('custom-button');
    button.addEventListener('click', callback);
    return button;
  }
  
  export function createCustomDrawingControls(map, drawingManager) {
    const customDrawingControls = document.createElement('div');
    customDrawingControls.classList.add('custom-drawing-controls');
  
    const drawMarkerBtn = createCustomButton('Draw Marker', () => {
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.MARKER);
    });
  
    const drawCircleBtn = createCustomButton('Draw Circle', () => {
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.CIRCLE);
    });
  
    const drawPolygonBtn = createCustomButton('Draw Polygon', () => {
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    });
  
    const drawPolylineBtn = createCustomButton('Draw Polyline', () => {
      drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYLINE);
    });
  
    const stopDrawingBtn = createCustomButton('Stop Drawing', () => {
      drawingManager.setDrawingMode(null);
    });
  
    customDrawingControls.appendChild(drawMarkerBtn);
    customDrawingControls.appendChild(drawCircleBtn);
    customDrawingControls.appendChild(drawPolygonBtn);
    customDrawingControls.appendChild(drawPolylineBtn);
    customDrawingControls.appendChild(stopDrawingBtn);
  
    map.controls[google.maps.ControlPosition.TOP_CENTER].push(customDrawingControls);
  }
  