"use strict";

//working well, need to modularise everything to keep clean. just get the basic ui then extend

import { initializeApp } from "firebase/app";
//import { decompose } from "fernandez-polygon-decomposition";
//import { isClockwiseOrdered, orderClockwise } from "fernandez-polygon-decomposition";
import { polygon } from "@turf/turf";
import { decomp, makeCCW, quickDecomp, removeCollinearPoints, removeDuplicatePoints } from 'poly-decomp-es';
import { createCustomButton, createCustomDrawingControls } from './controls';

const firebaseConfig = {
  apiKey: "AIzaSyBrAyw0a8Z84YC4DBPg8S1qEG0kO7JArX0",
  authDomain: "dronecomps-3f4ed.firebaseapp.com",
  projectId: "dronecomps-3f4ed",
  storageBucket: "dronecomps-3f4ed.appspot.com",
  messagingSenderId: "647629856307",
  appId: "1:647629856307:web:ee6600759ec27f69198d46"
};

const app = initializeApp(firebaseConfig);

const turf = require('@turf/turf');
const rectangleGrid = require('@turf/rectangle-grid').default;

function initMap() {

    class Camera{
        constructor(sensorWidth, focalLength, imageWidth, imageHeight){
        this.sensorWidth = sensorWidth;
        this.focalLength = focalLength;
        this.imageWidth = imageWidth;
        this.imageHeight = imageHeight;
        }
    }

    let camera = new Camera(6.17, 4.49, 4000, 3000);

    let currentRotation = 0;
    let currentGsd = 3000;
    let currentNudge = 0;
    let currentSidelap = 700;
    let currentFrontlap = 400;
    let currentSpeed = 30;
    let sensorWidth = camera.sensorWidth;
    let focalLength = camera.focalLength;
    let imageWidth = camera.imageWidth;
    let imageHeight = camera.imageHeight;

    function lineWidthSeparation(currentGsd, imageWidth, currentSidelap) {
        let imageWidthInMetres = ((currentGsd/1000)*(imageWidth/100)); // ((3250/1000)*(4000/100)) = 130m on flat ground
        let separationDistance = imageWidthInMetres * (1-(currentSidelap/1000)); // 130 * (500/1000) = 65 || 130 * (1-(700/1000)) = 39m separation
        //console.log(`Photo distance on ground: GSD[${currentGsd/1000}] * WidthPixels[${imageWidth}] = ${imageWidthInMetres}m`);
        let degrees = turf.lengthToDegrees(separationDistance, 'metres');
        //console.log(`Line separation distance: Sidelap of ${currentSidelap/10}% with photo size gives separation distance of ${separationDistance}m = ${degrees} degrees`);
        //console.log(currentGsd, 'currentGsd');
        //console.log(imageWidth, 'imageWidth');
        //console.log(currentSidelap, 'currentSidelap');
        return degrees;
    }

    let lineWidth = lineWidthSeparation(currentGsd, imageWidth, currentSidelap);
    console.log(lineWidth, 'lineWidth');

    const map = new google.maps.Map(document.getElementById("map"), {
    center: { lat: -34.397, lng: 150.644 },
    zoom: 15,
    disableDefaultUI: true,
    disableDoubleClickZoom: true,
    fullscreenControl: true,
    });

    const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: null,
        drawingControl: false,
        drawingControlOptions: {
          position: google.maps.ControlPosition.TOP_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.POLYLINE,
          ],
          
        },
        circleOptions: {
          fillColor: "#ffff00",
          fillOpacity: 0.2,
          strokeWeight: 2,
          clickable: true,
          editable: true,
          zIndex: 1,
        },
        polygonOptions: {
            clickable: true,
            editable: true,
            geodesic: false,
        },
      });
    
    drawingManager.setMap(map);

    createCustomDrawingControls(map, drawingManager);

    let polygonList = [];
    let bBoxList = [];
    let gridList = [];
    let doubleGridList = [];
    let circleList = [];
    let polygonCount = 0;
    let waypointList = [];

    let myCircle = new google.maps.Circle;

    //a master menu which will be used to edit all polygons
    const masterMenu = document.createElement("div");
    //hide the master menu
    masterMenu.style.display = "none";
    masterMenu.classList.add("master-menu");
    map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(masterMenu);

    google.maps.event.addListener(drawingManager, 'circlecomplete', function(circle) {
        let radius = circle.getRadius();
        //console.log(circle);
        console.log(radius);
        drawingManager.setDrawingMode(null);
    });

    google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {

        let debugBbox;
        let debugDoubledBboxPoly;
        currentRotation = 0;
        console.log(this);
        
        let poly = new google.maps.Polygon({
            paths: polygon.getPath(),
            strokeColor: '#FF0000',
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: '#000000',
            fillOpacity: 0.18,
            zIndex: 100,
            editable: true,
            draggable: false,
            clickable: true,
            geodesic: false,
        });

        poly.rotation = currentRotation;

        polygon.setMap(null);

        let thisBoundingBox = makeBoundingBox(poly);
        console.log(thisBoundingBox);

        let thisGrid = makeGrid(poly, thisBoundingBox, lineWidth, currentRotation);
        console.log(thisGrid);

        let theseLines = makeLines(thisGrid);
        console.log(theseLines);

        let intersectionPoints = findIntersectionPoints(theseLines, poly, currentRotation);
        console.log(intersectionPoints);

        //convert the turf points to google maps points
        let googlePoints = [];
        let thisWaypointList = [];
        thisWaypointList.id = polygonCount;
        intersectionPoints.forEach((point) => {
            //console.log(point);
            let lat = point.geometry.coordinates[1];
            let lng = point.geometry.coordinates[0];
            thisWaypointList.push([lat, lng]);
            let googlePoint = new google.maps.LatLng(lat, lng);
            googlePoints.push(googlePoint);
        });
        waypointList.push(thisWaypointList);

        //draw a polyline connecting the google points
        let flightPath = new google.maps.Polyline({
            path: googlePoints,
            geodesic: false,
            strokeColor: '#FFFF00',
            strokeOpacity: 1.0,
            strokeWeight: 1.2,
            zIndex: 102,
            editable: false,
            draggable: false,
            clickable: false,
        });

        //add the flightPath to the map
        flightPath.setMap(map);

        //big green dot on the first point
        let firstPoint = new google.maps.Marker({
            position: googlePoints[0],
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 7,
                fillColor: "#FFAA00",
                fillOpacity: 1,
                strokeWeight: 0,
            },
            zIndex: 103,
        });
        firstPoint.setMap(map);

        //big red dot on the last point
        let lastPoint = new google.maps.Marker({
            position: googlePoints[googlePoints.length - 1],
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 7,
                fillColor: "#FF0000",
                fillOpacity: 1,
                strokeWeight: 0,
            },
            zIndex: 103,
        });
        lastPoint.setMap(map);


        //change all other polygons stroke color to black
        for (let i = 0; i < polygonList.length; i++) {
            polygonList[i].setOptions({strokeColor: '#000000'});
        }

        //give the polygon a unique id
        poly.set('id', polygonCount);
        polygonCount++;
        console.log(poly.get('id'));

        let path = poly.getPath();
        //add the polygon to the map
        poly.setMap(map);

        //add the polygon to the list of polygons
        polygonList.push(poly);

        //console.log(poly);
        drawingManager.setDrawingMode(null);

        //a menu for each polygon which will be used to edit that polygon
        //this menu will be added to the master menu when a polygon is created
        //and removed from the master menu when the polygon is deleted
        //only one polygon menu will be visible at a time

        const tabDiv = document.createElement("div");
        tabDiv.classList.add("tab-div");
        tabDiv.innerHTML = "Polygon";
        masterMenu.appendChild(tabDiv);

        const polygonMenu = document.createElement("div");
        //polygonMenu.classList.add("polygon-menu"+poly.get('id'));
        polygonMenu.classList.add("polygon-menu");

        const rotationSlider = document.createElement("input");
        rotationSlider.type = "range";
        rotationSlider.min = "0";
        rotationSlider.max = "359";
        rotationSlider.value = "0";
        rotationSlider.classList.add("rotation-slider");
        polygonMenu.appendChild(rotationSlider);
    
        let greenSlider = document.createElement("input");
        greenSlider.type = "range";
        greenSlider.min = "0";
        greenSlider.max = "255";
        greenSlider.value = "0";
        greenSlider.classList.add("green-slider");
        polygonMenu.appendChild(greenSlider);

        let blueSlider = document.createElement("input");
        blueSlider.type = "range";
        blueSlider.min = "0";
        blueSlider.max = "255";
        blueSlider.value = "0";
        blueSlider.classList.add("blue-slider");
        polygonMenu.appendChild(blueSlider);
        
        let opacitySlider = document.createElement("input");
        opacitySlider.type = "range";
        opacitySlider.min = "0";
        opacitySlider.max = "1";
        opacitySlider.step = "0.01";
        opacitySlider.value = "0.18";
        opacitySlider.classList.add("opacity-slider");
        polygonMenu.appendChild(opacitySlider);

        let deleteButton = document.createElement("button");
        deleteButton.innerHTML = "Delete";
        deleteButton.classList.add("delete-button");
        polygonMenu.appendChild(deleteButton);

        deleteButton.addEventListener("click", function() {
            poly.setMap(null);
            poly.unbindAll();
            polygonList.splice(poly.get('id'), 1);
            flightPath.unbindAll();
            flightPath.setMap(null);
            firstPoint.unbindAll();
            firstPoint.setMap(null);
            lastPoint.unbindAll();
            lastPoint.setMap(null);
            masterMenu.removeChild(tabDiv);
            masterMenu.removeChild(polygonMenu);
            //debugBbox.setMap(null);
            //debugDoubledBboxPoly.setMap(null);
            polygonCount--;

            //update the ids of the polygons
            for (let i = 0; i < polygonList.length; i++) {
                polygonList[i].set('id', i);
            }

            polygonMenu.remove();
            tabDiv.remove();
            console.log(polygonList);
            //for each tab div, if the id is greater than the deleted polygon's id, subtract 1 from the id
            for (let i = 0; i < masterMenu.children.length; i++) {
                if (masterMenu.children[i].classList.contains("tab-div")) {
                    if (masterMenu.children[i].innerHTML.slice(8) > poly.get('id')) {
                        masterMenu.children[i].innerHTML = "Polygon " + parseInt(masterMenu.children[i].innerHTML.slice(8)-1);
                    }
                    //set the polygonCount to the number of polygons
                    polygonCount = masterMenu.children.length;
                    console.log(polygonList[i]);
                }
                //recount the polygons and set ids
                for (let i = 0; i < polygonList.length; i++) {
                    //for each polygon, set the id to the index of the polygon in the polygonList
                    polygonList[i].set('id', i);
                }
            }

            //if there are no polygons, hide the master menu
            if (polygonList.length == 0) {
                masterMenu.style.display = "none";
            }
        });

        //3d button that's toggled on and off
        let threeDButton = document.createElement("button");
        threeDButton.innerHTML = "3D";
        threeDButton.classList.add("3d-button");
        polygonMenu.appendChild(threeDButton);

        let orthoClicked = false;

        let polygonMenus = document.getElementsByClassName("polygon-menu");
            for (let i = 0; i < polygonMenus.length; i++) {
                polygonMenus[i].style.display = "none";
            }
            polygonMenu.style.display = "block";

        //when you click on a polygon tab, the corresponding polygon menu will be displayed
        tabDiv.addEventListener("click", function() {
            //hide all polygon menus
            let polygonMenus = document.getElementsByClassName("polygon-menu");
            for (let i = 0; i < polygonMenus.length; i++) {
                polygonMenus[i].style.display = "none";
            }
            //show the polygon menu corresponding to the polygon tab that was clicked
            polygonMenu.style.display = "block";

            //change the stroke color of the polygon to red
            poly.setOptions({
                strokeColor: '#FF0000',
            });

            //change the stroke color of the other polygons to black
            for (let i = 0; i < polygonList.length; i++) {
                if (polygonList[i].get('id') != poly.get('id')) {
                    polygonList[i].setOptions({
                        strokeColor: '#000000',
                    });
                }
            }
        });


        //on change
        rotationSlider.oninput = function() {
            //poly.setMap(null);
            flightPath.setPath([]);
            firstPoint.setPosition = null;
            lastPoint.setPosition = null;
            firstPoint.setMap(null);
            lastPoint.setMap(null);
            //flightPath.setMap(null);
            //debugBbox.path = [];
            //debugDoubledBboxPoly.path = [];

            currentRotation = parseInt(this.value);
            //alert("rotation: " + currentRotation);
            thisBoundingBox = null;
            thisBoundingBox = makeBoundingBox(poly);
            console.log(thisBoundingBox);
            
            thisGrid = null;
            thisGrid = makeGrid(poly, thisBoundingBox, lineWidth, currentRotation);
            console.log(thisGrid);

            theseLines = null;
            theseLines = makeLines(thisGrid);
            console.log(theseLines);

            intersectionPoints = null;
            intersectionPoints = findIntersectionPoints(theseLines, poly, currentRotation);
            console.log(intersectionPoints);

            //convert the turf points to google maps points
            googlePoints = [];
            console.log(googlePoints);
            intersectionPoints.forEach((point) => {
                //console.log(point);
                let lat = point.geometry.coordinates[1];
                let lng = point.geometry.coordinates[0];
                let googlePoint = new google.maps.LatLng(lat, lng);
                googlePoints.push(googlePoint);
            });

            //draw a polyline connecting the google points
            flightPath = new google.maps.Polyline({
                path: googlePoints,
                geodesic: false,
                strokeColor: '#FFFF00',
                strokeOpacity: 1.0,
                strokeWeight: 1.2,
                zIndex: 102,
                editable: false,
                draggable: false,
                clickable: false,
            });

            //add the flightPath to the map
            flightPath.setMap(map);

            //big green dot on the first point
            //firstPoint.setPath([null])
            firstPoint = new google.maps.Marker({
                position: googlePoints[0],
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 7,
                    fillColor: "#FFAA00",
                    fillOpacity: 1,
                    strokeWeight: 0,
                },
                zIndex: 103,
            });
            firstPoint.setMap(map);

            //big red dot on the last point
            //lastPoint.setPath([null]);
            lastPoint = new google.maps.Marker({
                position: googlePoints[googlePoints.length - 1],
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 7,
                    fillColor: "#FF0000",
                    fillOpacity: 1,
                    strokeWeight: 0,
                },
                zIndex: 103,
            });
            lastPoint.setMap(map);
        }

        greenSlider.oninput = function() {
            poly.setOptions({fillColor: 'rgb('+rotationSlider.value+','+greenSlider.value+','+blueSlider.value+')'});
        }
        blueSlider.oninput = function() {
            poly.setOptions({fillColor: 'rgb('+rotationSlider.value+','+greenSlider.value+','+blueSlider.value+')'});
        }

        opacitySlider.oninput = function() {
            poly.setOptions({fillOpacity: opacitySlider.value});
        }


        masterMenu.appendChild(polygonMenu);
        //if master menu is not visible, make it visible
        if (masterMenu.style.display == "none") {
            masterMenu.style.display = "block";
        }
        
        //when you click on a polygon, the corresponding polygon will have its menu displayed
        google.maps.event.addListener(poly, 'click', function(event) {
            //hide all polygon menus
            let polygonMenus = document.getElementsByClassName("polygon-menu");
            for (let i = 0; i < polygonMenus.length; i++) {
                polygonMenus[i].style.display = "none";
            }
            //show the master menu
            masterMenu.style.display = "block";
            //show the polygon menu corresponding to the polygon that was clicked
            polygonMenu.style.display = "block";
            console.log(poly.get('id'));

            //change the stroke color of the polygon to red
            poly.setOptions({
                strokeColor: '#FF0000',
            });

            //change the stroke color of the other polygons to black
            for (let i = 0; i < polygonList.length; i++) {
                if (polygonList[i].get('id') != poly.get('id')) {
                    polygonList[i].setOptions({
                        strokeColor: '#000000',
                    });
                }
            }
            currentRotation = parseInt(document.getElementsByClassName("rotation-slider")[0].value);
            console.log(currentRotation);
        });

        //add a listener for when the polygon vertices are changed
        google.maps.event.addListener(poly.getPath(), 'set_at', function() {
            //poly.setMap(null);
            currentRotation = parseInt(document.getElementsByClassName("rotation-slider")[0].value);
            flightPath.setPath([]);
            firstPoint.setPosition = null;
            lastPoint.setPosition = null;
            firstPoint.setMap(null);
            lastPoint.setMap(null);
            //flightPath.setMap(null);
            //debugBbox.path = [];
            //debugDoubledBboxPoly.path = [];

            //alert("rotation: " + currentRotation);
            thisBoundingBox = null;
            thisBoundingBox = makeBoundingBox(poly);
            console.log(thisBoundingBox);
            
            thisGrid = null;
            thisGrid = makeGrid(poly, thisBoundingBox, lineWidth, currentRotation);
            console.log(thisGrid);

            theseLines = null;
            theseLines = makeLines(thisGrid);
            console.log(theseLines);

            intersectionPoints = null;
            intersectionPoints = findIntersectionPoints(theseLines, poly, currentRotation);
            console.log(intersectionPoints);

            //convert the turf points to google maps points
            googlePoints = [];
            console.log(googlePoints);
            intersectionPoints.forEach((point) => {
                //console.log(point);
                let lat = point.geometry.coordinates[1];
                let lng = point.geometry.coordinates[0];
                let googlePoint = new google.maps.LatLng(lat, lng);
                googlePoints.push(googlePoint);
            });

            //draw a polyline connecting the google points
            flightPath = new google.maps.Polyline({
                path: googlePoints,
                geodesic: false,
                strokeColor: '#FFFF00',
                strokeOpacity: 1.0,
                strokeWeight: 1.2,
                zIndex: 102,
                editable: false,
                draggable: false,
                clickable: false,
            });

            //add the flightPath to the map
            flightPath.setMap(map);

            //big green dot on the first point
            //firstPoint.setPath([null])
            firstPoint = new google.maps.Marker({
                position: googlePoints[0],
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 7,
                    fillColor: "#FFAA00",
                    fillOpacity: 1,
                    strokeWeight: 0,
                },
                zIndex: 103,
            });
            firstPoint.setMap(map);

            //big red dot on the last point
            //lastPoint.setPath([null]);
            lastPoint = new google.maps.Marker({
                position: googlePoints[googlePoints.length - 1],
                icon: {
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 7,
                    fillColor: "#FF0000",
                    fillOpacity: 1,
                    strokeWeight: 0,
                },
                zIndex: 103,
            });
            lastPoint.setMap(map);
    });
   
    map.addListener('click', function(event) {
        //set the stroke color of all polygons to black
        for (let i = 0; i < polygonList.length; i++) {
            polygonList[i].setOptions({
                strokeColor: '#000000',
            });
        }
        //hide the master menu
        masterMenu.style.display = "none";
    });

    //add a listener for when the polygon has its vertices changed
    google.maps.event.addListener(polygon.getPath(), 'set_at', function() {
        
        
    });

    //add a listener for when a vertex is added to the polygon
    google.maps.event.addListener(polygon.getPath(), 'insert_at', function() {
        let rotation = document.getElementsByClassName("rotation-slider")[0].value;
        console.log(rotation);
        //poly.setMap(null);
        flightPath.setPath([]);
        firstPoint.setPosition = null;
        lastPoint.setPosition = null;
        firstPoint.setMap(null);
        lastPoint.setMap(null);
        //flightPath.setMap(null);
        //debugBbox.path = [];
        //debugDoubledBboxPoly.path = [];
        //alert("rotation: " + currentRotation);
        thisBoundingBox = null;
        thisBoundingBox = makeBoundingBox(poly);
        console.log(thisBoundingBox);
        
        thisGrid = null;
        thisGrid = makeGrid(poly, thisBoundingBox, lineWidth, rotation);
        console.log(thisGrid);

        theseLines = null;
        theseLines = makeLines(thisGrid);
        console.log(theseLines);

        intersectionPoints = null;
        intersectionPoints = findIntersectionPoints(theseLines, poly, rotation);
        console.log(intersectionPoints);

        //convert the turf points to google maps points
        googlePoints = [];
        console.log(googlePoints);
        intersectionPoints.forEach((point) => {
            //console.log(point);
            let lat = point.geometry.coordinates[1];
            let lng = point.geometry.coordinates[0];
            let googlePoint = new google.maps.LatLng(lat, lng);
            googlePoints.push(googlePoint);
        });

        //draw a polyline connecting the google points
        flightPath = new google.maps.Polyline({
            path: googlePoints,
            geodesic: false,
            strokeColor: '#FFFF00',
            strokeOpacity: 1.0,
            strokeWeight: 1.2,
            zIndex: 102,
            editable: false,
            draggable: false,
            clickable: false,
        });

        //add the flightPath to the map
        flightPath.setMap(map);

        //big green dot on the first point
        //firstPoint.setPath([null])
        firstPoint = new google.maps.Marker({
            position: googlePoints[0],
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 7,
                fillColor: "#FFAA00",
                fillOpacity: 1,
                strokeWeight: 0,
            },
            zIndex: 103,
        });
        firstPoint.setMap(map);

        //big red dot on the last point
        //lastPoint.setPath([null]);
        lastPoint = new google.maps.Marker({
            position: googlePoints[googlePoints.length - 1],
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 7,
                fillColor: "#FF0000",
                fillOpacity: 1,
                strokeWeight: 0,
            },
            zIndex: 103,
        });
        lastPoint.setMap(map);
        
    });

    ////add a listener when the user drags a vertex
    //google.maps.event.addListener(drawingManager, 'overlaycomplete', function(event) {
    //    if (event.type == google.maps.drawing.OverlayType.POLYGON) {
    //        google.maps.event.addListener(event.overlay.getPath(), 'set_at', function() {
    //            //get the polygon that was dragged
    //            let draggedPolygon = event.overlay;
    //            //get the polygon's id
    //            let draggedPolygonId = draggedPolygon.get('id');
    //            console.log(draggedPolygonId);                
    //            //get the polygon
    //            let polygon = polygonList[id];
//
    //            //get the coordinates of the polygon
    //            let coords = polygon.getPath().getArray();
    //            //convert to array of lat/lng numbers
    //            let coordsArray = coords.map(function(coord) {
    //                return [coord.lng(),coord.lat()];
    //            }
    //            );
//
    //            //double size bbox
    //            let bboxPolygon = turf.bboxPolygon(turf.bbox(turf.polygon([coordsArray])));
    //            let doubledBboxPolygon = turf.transformScale(bboxPolygon, 2, {origin: turf.center(bboxPolygon)});
    //            bBoxList[id] = new google.maps.Polygon({
    //                paths: doubledBboxPolygon.geometry.coordinates[0].map(function(coord) {
    //                    return {lat: coord[1], lng: coord[0]};
    //                }
    //                ),
    //                strokeColor: '#0000FF',
    //                strokeOpacity: 0.8,
    //                strokeWeight: 2,
    //                fillColor: '#0000FF',
    //                fillOpacity: 0.35,
    //                map: map
    //            });                
    //        });
    //    }

    });

    function makeBoundingBox(polygon) {
    //BOUNDING BOX STUFF
    //turf functions
    let coords = polygon.getPath().getArray();
    //convert to array of lat/lng numbers
    let coordsArray = coords.map(function(coord) {
        return [coord.lng(),coord.lat()];
    });
    //copy first coord to end of array to satisfy geojson spec
    coordsArray.push(coordsArray[0]);
    console.log(coordsArray);
    //convert to turf polygon
    let turfPolygon = turf.polygon([coordsArray]);
    console.log(turfPolygon);
    //turf bbox polygon 
    let bboxPolygon = turf.bboxPolygon(turf.bbox(turfPolygon));
    console.log(bboxPolygon);
    //new google maps polygon
    //debugBbox = new google.maps.Polygon({
    //    paths: bboxPolygon.geometry.coordinates[0].map(function(coord) {
    //        return {lat: coord[1], lng: coord[0]};
    //    }),
    //    strokeColor: '#00FF00',
    //    strokeOpacity: 0.8,
    //    strokeWeight: 2,
    //    fillColor: '#00FF00',
    //    fillOpacity: 0.35,
    //    geodesic: false,
    //});
    //debugBbox.setMap(map);

    //double the size of the bbox polygon
    let turfBigBboxPolygon = turf.transformScale(bboxPolygon, 8, {origin: turf.center(bboxPolygon)});
    console.log(turfBigBboxPolygon);
    //convert the large bbox polygon to an actual bounding box
    let bboxExtent = turf.bbox(turfBigBboxPolygon);
    console.log(bboxExtent);
    //new google maps polygon
    //debugDoubledBboxPoly = new google.maps.Polygon({
    //    paths: turfBigBboxPolygon.geometry.coordinates[0].map(function(coord) {
    //        return {lat: coord[1], lng: coord[0]};
    //    }
    //    ),
    //    strokeColor: '#0000FF',
    //    strokeOpacity: 0.8,
    //    strokeWeight: 2,
    //    fillColor: '#0000FF',
    //    fillOpacity: 0.35,
    //    geodesic: false,
    //});
    //bBoxList.push(debugDoubledBboxPoly);
    //debugDoubledBboxPoly.setMap(map);
    //return the bounding box
    return bboxExtent;
    }

    function makeGrid(polygon, boundingBox, gridSpacingDegrees, rotation) {
        //make polygon into a turf polygon
        let coords = polygon.getPath().getArray();
        //convert to array of lat/lng numbers
        let coordsArray = coords.map(function(coord) {
            return [coord.lng(),coord.lat()];
        });
        //copy first coord to end of array to satisfy geojson spec
        coordsArray.push(coordsArray[0]);
        let turfPolygon = turf.polygon([coordsArray]);
        console.log(turfPolygon);

        let boundingBoxHeight = boundingBox[3] - boundingBox[1];
        console.log(boundingBoxHeight);
        //create a rectangle grid
        console.log(`bounding box height: ${boundingBoxHeight} degrees, grid spacing: ${gridSpacingDegrees} degrees, bounding box: ${boundingBox}`);
        let grid = rectangleGrid(boundingBox, gridSpacingDegrees, boundingBoxHeight*0.99, {units: 'degrees'});
        console.log(grid);
        //remove every nth feature from the grid feature collection
        for (let i = 0; i < grid.features.length; i++) {
            if (i % 1 === 0) {
                grid.features.splice(i, 1);
            }
        }
        let rotatedGrid = turf.transformRotate(grid, rotation, {pivot: turf.center(grid)});
        return rotatedGrid;
    }

    function makeLines(grid) {
        let gridPolys = grid.features;
        console.log(gridPolys);

        //for each feature in gridLines, create two new features, one for each side of the line
        //they go in opposite directions
        let lineSet = [];
        gridPolys.forEach((feature) => {
            let featureCoords = feature.geometry.coordinates;
            console.log(featureCoords);
            let line1 = turf.lineString([featureCoords[0][0], featureCoords[0][1]]);
            let line2 = turf.lineString([featureCoords[0][2], featureCoords[0][3]]);
            lineSet.push(line1);
            lineSet.push(line2);
        });
        //delete every other line
        //for (let i = 0; i < lineSet.length; i++) {
        //    if (i % 1 == 0) {
        //        lineSet.splice(i, 1);
        //    }
        //}

        console.log(lineSet);
        return lineSet;
    }

    function findIntersectionPoints(lineSet, polygon, rotation) {
        let i = 0;
        let polygonCoords = polygon.getPath().getArray();
        let polygonCoordsArray = polygonCoords.map(function(coord) {
            return [coord.lng(),coord.lat()];
        });
        polygonCoordsArray.push(polygonCoordsArray[0]);
        let turfPolygon = turf.polygon([polygonCoordsArray]);
        console.log(turfPolygon);
        let intersectionPoints = [];
        //get direction for the first line
        let firstLine = lineSet[0];
        let firstLineCoords = firstLine.geometry.coordinates;
        let firstLineDirection = (Math.round(turf.bearing(firstLineCoords[0], firstLineCoords[1]))+360)%360;
        if (firstLineDirection != rotation) {
            console.log("first line direction doesn't match rotation");
            console.log(firstLineDirection);
            console.log(rotation);
        }
        console.log(firstLineDirection);

        lineSet.forEach((line, i) => {
            console.log(i);
            //get direction for the line
            let lineCoords = line.geometry.coordinates;
            console.log(lineCoords);
            let lineDirection = (Math.round(turf.bearing(lineCoords[0], lineCoords[1]))+360)%360;
            let intersection = turf.lineIntersect(turfPolygon, line);
            console.log(intersection);
            //check the intersection for more than 2 points
            if (intersection.features.length > 0) {
                //sort the intersection points by distance from the first point in lineCoords
                let firstLineCoord = lineCoords[0];
                for (let i = 0; i < intersection.features.length; i++) {
                    let intersectionPoint = intersection.features[i];
                    intersectionPoint.properties.distance = turf.distance(firstLineCoord, intersectionPoint.geometry.coordinates);
                }
                intersection.features.sort((a, b) => {
                    return a.properties.distance - b.properties.distance;
                }
                );
            }
            //!!!!!!!! lineIntersect fucks up the order of the coordinates in the returned feature when transitioning across lines!!!!!!
            //need algo to re-order the coordinates
            if (intersection.features.length > 0) {
                if (i == 0) {
                    //the first intersected line sets the direction for the rest of the lines
                    if ((Math.round(turf.bearing(intersection.features[0].geometry, intersection.features[intersection.features.length-1]))+360)%360 == rotation) {
                        //add the first intersection point to the list
                        intersectionPoints.push(intersection.features[0].geometry.coordinates);
                        intersectionPoints.push(intersection.features[intersection.features.length-1].geometry.coordinates);
                    } else {
                        //add the last intersection point to the list
                        intersectionPoints.push(intersection.features[intersection.features.length-1].geometry.coordinates);
                        intersectionPoints.push(intersection.features[0].geometry.coordinates);
                    }
                } else {
                if (i % 2 == 0 && i != 0) {
                    //should be going same direction as currentRotation   
                    //get direction for the line
                    let lineDirection = (Math.round(turf.bearing(intersection.features[0].geometry, intersection.features[intersection.features.length-1]))+360)%360;
                    console.log(`%c lineDirection of ${i}: ${lineDirection}`, "color: pink");
                    if (lineDirection != rotation) {
                        console.log(`line ${i} direction doesn't match rotation`);
                        console.log(`${lineDirection} vs ${rotation}`);
                        console.log(`intersection.features.length = ${intersection.features.length}`);
                        intersectionPoints.push(intersection.features[intersection.features.length-1]);
                        intersectionPoints.push(intersection.features[0]); 
                    } else {
                        console.log(`line ${i} direction DOES match rotation`);
                        console.log(`${lineDirection} vs ${rotation}`);
                        console.log(`intersection.features.length = ${intersection.features.length}`);
                        intersectionPoints.push(intersection.features[0]);
                        intersectionPoints.push(intersection.features[intersection.features.length-1]);
                    }
                } else {
                if (lineDirection == rotation) {
                    console.log(`%c line ${i} direction DOES match rotation`, "color: red");
                    console.log(`${lineDirection} vs ${rotation}`);
                    console.log(`intersection.features.length = ${intersection.features.length}`);
                    intersectionPoints.push(intersection.features[intersection.features.length-1]);
                    intersectionPoints.push(intersection.features[0]);
                    } else {
                        intersectionPoints.push(intersection.features[0]);
                        intersectionPoints.push(intersection.features[intersection.features.length-1]);
                    }
                }
                }
            }
            i++;
        });
        //console.log(intersectionPoints);
        return intersectionPoints;
    }







}


window.initMap = initMap;
